const Drinks = [

    {
        id:39,
        name:"Tea",
        price:"1.5",
        mealQuantity:"normal"
    },

     
    {  id:40,
        name:"Coffee",
        price:"1.5",
        mealQuantity:"normal"
    },
   
     
    {   id:42,
        name:"Kashmiri Chai",
        price:"2",
        mealQuantity:"normal"
    },
   
    {
        id:41,
        name:"Bebinca",
        price:"20",
        mealQuantity: "per lb"
    }
    ,
  
   
    
    
   
    
    

]




export default Drinks;