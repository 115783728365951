import React from 'react'
import {Card, ListGroupItem, ListGroup, Container, Col, Row} from 'react-bootstrap'
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import FiveMenu from '../assets/images/5menu.png';
import Button from '@mui/material/Button';
import { Link} from "react-router-dom";
import Lunch from '../assets/images/Lunch.png'
import Menu5 from '../assets/images/menutile1.png'
import GMenu from '../assets/images/goan.png'
import IMenu from '../assets/images/Indian.png'
import '../assets/css/MenuTitle.css'
import DailyMenu from './DailyMenu'
import OrderChoris from "./OrderChoris"
const Menu = () => {
  return (
    <Container fluid style={{paddingLeft:"3rem"}}>
        <br />
      
        <Row xs={1} md={2} lg={4}>
          {/* Correction remove snacks */}
          <Col>      
        {/* 
 <Card style={{ width: '19rem'}}>
    <Card.Img variant="top" src={Menu5} alt="holder.js/100px180?text=Image cap" />
    <Card.Body>
      <Card.Title>Snack Deal</Card.Title>
      <Card.Text>
      Panrolls, Potato Chops...
      </Card.Text>

      <Button style={{backgroundColor:"yellow", borderRadius:"10px"}}>
      <Link to="/menu/snacks" style={{textDecoration:"none", fontSize:"15px", color:"black", padding:"2px"}}>
                      Full Menu
            </Link>
        </Button>  
    </Card.Body>
  </Card>
*/}
  
</Col> 

  <Col>
  <Card style={{ width: '19rem' }}>
    <Card.Img variant="top" src={GMenu} alt="Goan Menu"/>
    <Card.Body>
      <Card.Title>Goan Menu</Card.Title>
      <Card.Text>
      Goan Fish Curry, Xacuti...
      </Card.Text>
      
      <Button style={{backgroundColor:"yellow", borderRadius:"10px"}}>
      <Link to="/menu/goan" style={{textDecoration:"none", fontSize:"15px", color:"black", padding:"2px"}}>
                      Full Menu
            </Link>
        </Button>
    
    </Card.Body>
 
  </Card>
        </Col>
 {/* 
        <Col>      
 <Card style={{ width: '19rem' }}>
    <Card.Img variant="top" src={Lunch} alt="daily specials" />
    <Card.Body>
      <Card.Title>Daily Specials</Card.Title>
      <Card.Text>
      Check To Find Out More
      </Card.Text>
      
        <DailyMenu/>
    </Card.Body>
  </Card>
  </Col> */}

  <Col>      
 <Card style={{ width: '19rem' }}>
    <Card.Img variant="top" src={IMenu} alt="Indian menu"/>
    <Card.Body>
      <Card.Title>Indian Menu</Card.Title>
      <Card.Text>
     Biryani, Butter Chicken...
      </Card.Text>
      <Button style={{backgroundColor:"yellow", borderRadius:"10px"}}>
      <Link to="/menu/indian" style={{textDecoration:"none", fontSize:"15px", color:"black", padding:"2px"}}>
                     Full Menu
            </Link>
        </Button>
    
    </Card.Body>
  </Card>
  </Col>
        </Row>
    </Container>
       
      


  

  )
}

export default Menu