import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
import Header2 from "../components/Common/Header"
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.transparent,
 boxShadow: "none"
}));
const HomePage = () => {
  const location = useLocation(); 
  return  (
    <>
   { location.pathname === "/menu/snacks" ?<Helmet >
  <title>Goan Snacks</title>
  <link rel="notImportant" href="https://nostalgiakitchen.ca/menu/snacks/" />
  <meta name="description"   content="We have a 2 for $5 Deal on Most Menu Items" />
  <link rel="canonical" href="https://nostalgiakitchen.ca/menu/snacks" />
  <meta property="og:title" content="Nostalgia Kitchen - Taste of Goa"/>
  <meta property="og:type" content="website"/>
  <meta property="og:url" content="https://nostalgiakitchen.ca/menu/snacks/"/>
  
</Helmet>
 : location.pathname === "/menu/goan" ? <Helmet >
 <title>Traditional Goan Menu</title>
 <link rel="notImportant" href="https://nostalgiakitchen.ca/menu/goan/" />
 <meta name="description"   content="Goan Food Menu, Xacuti, Xec Xec, Amazin Goan Fish Curry and Rice " />
 <link rel="canonical" href="https://nostalgiakitchen.ca/menu/goan" />
 <meta property="og:title" content="Nostalgia Kitchen - Ami Goanchar"/>
 <meta property="og:type" content="website"/>
 <meta property="og:url" content="https://nostalgiakitchen.ca/menu/goan/"/>
 
</Helmet> :
location.pathname === "/menu/indian" ?  <Helmet >
<title>Traditional Goan Menu</title>
<link rel="notImportant" href="https://nostalgiakitchen.ca/menu/indian/" />
<meta name="description"   content="Butter Chicken, Biryani, Chicken Amritsari and other selected items" />
<link rel="canonical" href="https://nostalgiakitchen.ca/menu/indian" />
<meta property="og:title" content="Nostalgia Kitchen - Indian Menu"/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://nostalgiakitchen.ca/menu/indian/"/>

</Helmet> :  <Helmet >
<title>Nostalgia Kitchen - Menu</title>
<link rel="notImportant" href="https://www.nostalgiakitchen.ca/menu" />
<meta name="description"   content="View Our Delicious Goan Menu" />
<link rel="canonical" href="https://www.nostalgiakitchen.ca/menu" />
<meta property="og:title" content="Nostalgia Kitchen - Taste of Goa"/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.nostalgiakitchen.ca/menu"/>

</Helmet> 

}
   





   
  <Box sx={{ width: '100%' }}> 
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
    <Grid item xs={12}>
          <Item sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                    <Header2 />
                    {/* <Header /> */}
                 
                  </Item>
                  <Item sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <Header />
                 
                  </Item>
          {/* <Item> <Header /> 
             <Header2 />
          </Item> */}
        </Grid>
         <Grid item xs={12} >
          <Item><Banner /></Item>
        </Grid>
        
       
    <Footer />
    </Grid>   
  </Box>
  </>
  )
};

export default HomePage;
