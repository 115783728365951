import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom'
import { AiFillHome } from "react-icons/ai";
import { GiArchiveRegister } from "react-icons/gi";
import { BsFillCartCheckFill } from "react-icons/bs";
import Typography from '@mui/material/Typography';
import { MdHeadphonesBattery, MdChair } from "react-icons/md";
import { BsFillHandbagFill } from "react-icons/bs";
import { RiShoppingCart2Fill } from "react-icons/ri";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import CollectionsIcon from '@mui/icons-material/Collections';
import { SiCodechef } from "react-icons/si";
import {BsCalendarEvent} from "react-icons/bs";
export default function SwipeableTemporaryDrawer(props) {
  const [state, setState] = React.useState({left: false});

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 230 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     <List>
        
      <ListItem>
          <Typography variant="h5" style={{color:"black"}} >

          Nostalgia Kitchen
          </Typography>
      </ListItem>

      <ListItem>
        <Link to="/" style={{textDecoration:"none"
      , alignItems:"left", display:"inline-block", verticalAlign:"5px"
    }}>
        <ListItemIcon style={{  alignItems:"left", display:"inline-block", verticalAlign:"5px"}}>
                <AiFillHome size={30}  style={{color:"#FFB81C"}}/>
        </ListItemIcon>
            <Button style={{color:"#FFB81C"}}>Home</Button>
        </Link>
      </ListItem>

      <ListItem>
        <Link to="/menu" style={{textDecoration:"none"}}>
        <ListItemIcon>
                <RestaurantMenuIcon size={30}  style={{color:"#FFB81C"}}/>
        </ListItemIcon>
            <Button style={{color:"#FFB81C"}}>MENU</Button>
        </Link>
      </ListItem>
      
      <ListItem>
        <Link to="/weeklymenu" style={{textDecoration:"none"}}>
        <ListItemIcon>
                <RestaurantMenuIcon size={30}  style={{color:"#FFB81C"}}/>
        </ListItemIcon>
            <Button style={{color:"#FFB81C"}}> Weekly MENU</Button>
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/events" style={{textDecoration:"none"}}>
        <ListItemIcon>
                <BsCalendarEvent size={25}  style={{color:"#FFB81C"}}/>
        </ListItemIcon>
            <Button style={{color:"#FFB81C"}}> Events</Button>
        </Link>
      </ListItem>
      

      


      </List>
      <Divider />
      <ListItem>
        <Link to="/about" style={{textDecoration:"none"}}>
        <ListItemIcon>
                <SiCodechef size={30}  style={{color:"#FFB81C"}}/>
        </ListItemIcon>
            <Button style={{color:"#FFB81C"}}>About Us</Button>
        </Link>
      </ListItem>

      <List>
      <ListItem>
         
      </ListItem>

     
      

         
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}> 
          <MenuIcon style={{color:"gray"}} fontSize="large"/>
          
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}