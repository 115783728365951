import React from 'react';
import Cart from "../../Context/CartContext"
import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const OnlineCart = () => {
  const {cart, setCart} = useContext(Cart);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notAvailable, setNotAvailable] = useState([])
  const [time, setTime] = useState("11:00 AM")
  const [error, setError] = useState(false)
  const [itemsError, setItemsError] = useState(false)
 
  useEffect(()=>{
  
    // setTime("11:00 AM")
  },[cart])

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  const removeUnavailableItems = ()=>{

    const myArray = cart
    const toRemove = new Set(notAvailable);
    
    const difference = myArray.filter( x => toRemove.has(x._id) );
    setCart(difference)
    setNotAvailable([])
    console.log(difference)
  }
  const submitOrder = (e) =>{
    e.preventDefault();
    if(name === ""){
      setNameError("Please Enter your name")
    }
    if(email === ""){
      setEmailError("Please Enter your email")
    }
    if(phone === ""){
      setPhoneError("Please Enter your Phone number")
    }
    if(name && phone && email ){
      setLoading(true)
      let data = {
        name: name,
        phone: phone,
        email: email,
        time: time,
        cart: cart,
        tax:  Math.floor(cart.reduce((accumulator, object) => {
          return (accumulator + object.description*object.price *.13);
        }, 0)*100)/100 ,
        total: cart.reduce((accumulator, object) => {
          return (accumulator + object.description*object.price );
        }, 0) + Math.floor(cart.reduce((accumulator, object) => {
          return (accumulator + object.description*object.price *.13);
        }, 0)*100)/100


      }
      var canContinue = true
      fetch("https://admin.billfern.ca/chorisPav")
      .then(response=>response.json())
      .then((data)=>{
     
       
        cart.forEach((el)=>{
          let find = data.data.filter((el1)=> el1._id === el._id)
          console.log(find.isAvailable)

          if(find[0].isAvailable === false){
            setItemsError(true)
            setNotAvailable([...notAvailable, find[0]])
            console.log(notAvailable)
            canContinue = false
           return canContinue = false
          }
        })
        // console.log(data.data)
        if(!canContinue){
          setLoading(false)
        }
      })
    if(canContinue){

      fetch("https://admin.billfern.ca/sendOrder",{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      
      } ).then(response=>response.json()).then((data)=>
      
      {
        if(data.data === "success"){
              setLoading(false)
            setName("") 
            setEmail ("") 
            setPhone("") 
            setSuccess(true)
            setError(false)
            if(notAvailable.length === 0){
              setTimeout(
                () => setCart([]),
                1500
              );
            }
      
            
      }}
      
      ).catch((e)=>{console.log(e);    setError(true);     setSuccess(false);  setLoading(false)})
    }
    }
  }
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
            <hr
        style={{
            color: "gray",
            backgroundColor:  "gray",
            height: 5
        }}
    />
            <h1>Cart</h1> 
    
            <table>
                  <thead>
                      <tr>
                          <th style={{width:"30%", wordBreak:"break-all", padding:"5px"}}>Name</th>
                          <th style={{width:"30%", wordBreak:"break-all", padding:"5px"}}>Quantity </th>
                          <th style={{width:"30%", wordBreak:"break-all", padding:"5px"}}>Price</th>
                      
                      </tr>

                  </thead>
                  <tbody>
      {cart.map((el, i)=>(
      
      
      <tr>
          <td>  {el.name} </td>
          <td> {el.description}</td>
          <td> ${el.description *el.price}</td>      
        </tr>
    ))}
      </tbody>
    
    </table>
    <hr
        style={{
            color: "gray",
            backgroundColor:  "gray",
            height: 5
        }}
    />
      
    <h2>Tax:${ Math.floor(cart.reduce((accumulator, object) => {
  return (accumulator + object.description*object.price *.13);
}, 0)*100)/100}</h2>
    <h2>Total:$ { cart.reduce((accumulator, object) => {
  return (accumulator + object.description*object.price );
}, 0) + Math.floor(cart.reduce((accumulator, object) => {
  return (accumulator + object.description*object.price *.13);
}, 0)*100)/100}</h2>
<br />
<h1>Contact Info</h1> 
      <Stack spacing={2}>
        <TextField size="small" id="standard-basic" label="Name" variant="outlined" error={nameError}  value={name} onChange={(e)=>{setName(e.target.value); setNameError("")}}/>     
        <span style={{color:"red"}}>{nameError}</span>
        <TextField size="small" id="standard-basic" label="Email" variant="outlined" error={emailError} value={email} onChange={(e)=>{setEmail(e.target.value); setEmailError("")}}/>   
        <span style={{color:"red"}}>{emailError}</span>
        <TextField size="small" id="standard-basic" label="Phone Number" error={phoneError} value={phone} variant="outlined" onChange={(e)=>{setPhone(e.target.value); setPhoneError("")}}/>    
        <span style={{color:"red"}}>{phoneError}</span>
        <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Pick Up Time</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={time ? time : "11: 00AM"}
            label="Pick"
            onChange={handleChange}
           
        >
            <MenuItem value={"11:00 AM"}>11:00 AM</MenuItem>
          <MenuItem value={"11:30 AM"}>11:30 AM</MenuItem>
          <MenuItem value={"12:00 PM"}>12:00 PM</MenuItem>
          <MenuItem value={"12:30 PM"}>12:30 PM</MenuItem>
          <MenuItem value={"01:00 PM"}>01:00 PM</MenuItem>
          <MenuItem value={"01:30 PM"}>01:30 PM</MenuItem>
          <MenuItem value={"02:00 PM"}>02:30 PM</MenuItem>
          <MenuItem value={"02:30 PM"}>02:30 PM</MenuItem>
          <MenuItem value={"03:00 PM"}>03:00 PM</MenuItem>
          <MenuItem value={"03:30 PM"}>03:30 PM</MenuItem>
          <MenuItem value={"04:00 PM"}>04:00 PM</MenuItem>
          <MenuItem value={"04:30 PM"}>04:30 PM</MenuItem>
          <MenuItem value={"05:00 PM"}>05:00 PM</MenuItem>
          <MenuItem value={"05:30 PM"}>05:30 PM</MenuItem>
          <MenuItem value={"06:00 PM"}>06:00 PM</MenuItem>
          <MenuItem value={"06:30 PM"}>06:30 PM</MenuItem>
        </Select>
      </FormControl>
    </Box>
      </Stack>
      <br />
      {loading ? <CircularProgress style={{color:"#8B0000"}}/> : <Button disabled={notAvailable.length > 0 ? true : false} onClick={(e)=>submitOrder(e)}style={{  backgroundColor: notAvailable.length > 0 ? "gray" :"#8B0000",
        color:"white",
        marginRight:"10px",
        marginBottom:"10px",
        border: '1px black solid',
        borderRadius:"0px"}}>Place Order</Button> }
        {notAvailable.map((el)=>(
            <div>
              {el.name} is not available, please contact Nostalgia Kitchen, or remove from cart. 
            </div>
        ))}
        {/* {notAvailable.length > 0 && <Button onClick={(e)=>{removeUnavailableItems(e)}}>Click here to remove all Unavailable Items</Button>} */}
        <br />
        {itemsError && <span style={{color:"red"}}> Some of the Item(s) are out of stock <br /> Please contact nostalgia kitchen at <br /> 416-792-7060, to place order</span>}
        {error &&  <span style={{color:"red"}}> Unfortunately Order was not processed. <br /> Please contact nostalgia kitchen at <br /> 416-792-7060, to place order</span>}
       {success && notAvailable.length === 0 &&  <span style={{color:"darkgreen"}}> Order Placed Successfully. <br /> Thank You For Choosing Us</span>}
   </div>
  )
}

export default OnlineCart