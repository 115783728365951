import React from 'react';
import {Image, Carousel, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import snacks from './DealsDB'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Snacks from './Snacks'
import Curries from './GCurriesDB'
import Icurries from './ICurriesDB';
import DrinksDB from './DrinksDB';
import {useEffect, useState, useContext} from 'react';
import RiceDB from './RiceDB'
import Snack1DB from './Snack1DB'
import Cart from './Cart.js'
import CartContext from '../Context/CartContext';
import { FixedSizeList } from 'react-window';
import ShowCart from './ShowCart';
import HowItWorks from './HowItWorks';
import { useParams } from "react-router-dom";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import CollectionsIcon from '@mui/icons-material/Collections';
import { SiCodechef } from "react-icons/si";
import   OrderChoris from "./OrderChoris";
import OnlineItems from './OnlineMenu/OnlineItems';
import OnlineCart from "./OnlineMenu/OnlineCart";
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    alignContent:'right',
    color: theme.palette.text.secondary,
    boxShadow: "none",
   
  
    
  }));
const Banner = () => {
    const paperStyle ={
        padding: '30px 20px', 
        margin: '20px auto',
        backgroundColor:"#FFB81C"
      }
      const menuStyle ={
        padding: '30px 20px', 
        width: {xs:"100%", md:"100%"},
        margin: '20px auto',
        backgroundColor:"white"
      }

      const deactive ={
          backgroundColor:"white",
          color:"black",
          marginRight:"10px",
          border: '1px black solid',
          marginBottom:"10px",
          borderRadius:"0px"
      }

      const active = {
        backgroundColor:"#8B0000",
        color:"white",
        marginRight:"10px",
        marginBottom:"10px",
        border: '1px black solid',
        borderRadius:"0px"
      }
      //taking out snacks correction
      const [snacksFlag, setSnacksFlag] = useState(true)
      const [goanFlag, setGoanFlag] = useState(false)
      const [indianFlag, setIndianFlag] = useState(false)
      const [drinksFlag, setDrinksFlag] = useState(false)
      const [riceFlag, setRiceFlag] = useState(false)
      const [oneFlag, setOneFlag] = useState(false)
      const {cart, setCart} = useContext(CartContext)
      const [showCart, setShowCart] = useState(false)
      const [showChoris, setShowChoris] = useState(false)
      const [price, setPrice] = useState(0)
      const [availableName, setAvailableName] = useState("")
      const [quantity, setQuantity] = useState(0)
      const [onlineItems, setOnlineItems] = useState(false);
      const [showOnlineMenu, setShowOnlineMenu] = useState(false);
      const [onlineMeals, setOnlineMeals] = useState([])
      const [loading, setLoading] = useState([])
      
      const { name } = useParams();
      useEffect(()=>{
          if(name){
              if(name === "snacks"){
                setSnacksFlag(false);
            setGoanFlag(false);
            setIndianFlag(false);
            setDrinksFlag(false);
            setRiceFlag(false)
            setOneFlag(true)
            setCart([])
            setShowCart(false)    
              }
            if(name === "goan"){
                setSnacksFlag(false);
                setGoanFlag(true);
                setIndianFlag(false);
                setDrinksFlag(false);
                setRiceFlag(false)
                setOneFlag(false)
                setCart([])
                setShowCart(false)
            }

            if(name === "indian"){
                setSnacksFlag(false);
                setGoanFlag(false);
                setIndianFlag(true);
                setDrinksFlag(false);
                setRiceFlag(false)
                setOneFlag(false)
                setCart([])
                setShowCart(false)
            }
          
          } else {
                //correction
              
              setGoanFlag(false);
              setIndianFlag(false);
              setDrinksFlag(false);
              setRiceFlag(false)
              setOneFlag(false)
              setCart([])
              setShowCart(false)
          }
        

              getMeals()
          
      } , [])

      const getMeals = ()=>{
        setLoading(true)
        fetch("https://admin.billfern.ca/chorisPav")
          .then(response=>response.json())
          .then((data)=>{
            
            if(data.data.length < 1){
                setShowOnlineMenu(false)
                setSnacksFlag(true);

            } else {
                let showItem = false
                let meals = data.data.map((el)=>{
                    el.description = 0
                })
                // console.log(meals)
                let tempMeals = []
                data.data.forEach((el)=>{
                    if(el.isAvailable === true){
                        showItem = true
                        tempMeals.push(el)
                    }
                });
                if(showItem){
                    setShowOnlineMenu(true)
                }
                if(showItem && !name ){
                    setOnlineItems(true)
                    setShowOnlineMenu(true)
                    setOnlineMeals(tempMeals)
                    setSnacksFlag(false);
                } else if (!name){
                    setShowOnlineMenu(false)
                    setSnacksFlag(true);
                }
            }
            setLoading(false)
        
        })
          .catch((e)=>{
            if(!name){                
                setSnacksFlag(true);
            }
            setShowOnlineMenu(false)
            setLoading(false)
            console.log(e)})
      }

      const addItem = (i) =>{
        let temp = onlineMeals
        temp[i].description = temp[i].description + 1
        setOnlineMeals(temp)

      }

      const changeSnackFlag =()=>{
        setSnacksFlag(true)
        setGoanFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setRiceFlag(false)
        setOneFlag(false)
        setOnlineItems(false)

    }
      const changeGoanFlag =()=>{
        setGoanFlag(true)
        setSnacksFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setRiceFlag(false)
        setOneFlag(false)
        setOnlineItems(false)

    }
      const changeIndianFlag =()=>{
        setIndianFlag(true)
        setSnacksFlag(false);
        setDrinksFlag(false);
        setGoanFlag(false)
        setRiceFlag(false)
        setOneFlag(false)
        setOnlineItems(false)

      }
      const changeDrinkFlag =()=>{
        setDrinksFlag(true)
        setSnacksFlag(false);
        setIndianFlag(false)
        setGoanFlag(false)
        setRiceFlag(false)
        setOneFlag(false)
        setOnlineItems(false)

    }

      const changeRiceFlag = () =>{
        setRiceFlag(true)
        setSnacksFlag(false);
        setGoanFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setOneFlag(false)
        setOnlineItems(false)

      }

      const changeOneFlag = () =>{
        setSnacksFlag(false);
        setGoanFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setRiceFlag(false)
        setOneFlag(true)
        setOnlineItems(false)
      }
      const changeOnlineItemsFlag = () =>{
       
        setOnlineItems(true)
        setSnacksFlag(false);
        setGoanFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setRiceFlag(false)
        setOneFlag(false)
      }
     const onAddCart = (temp) =>{

          setCart([...cart, temp])
          setShowCart(true)
         
      }


      
  return (
    <div>

    
          {loading ? <CircularProgress style={{color:"#8B0000"}}/> : <>   

        <Typography variant="h3" component="div" gutterBottom style={{color:"black"}}>
                   Menu
                </Typography> 
                {/* correction taking out snacks button */}
                {/* <Button style={oneFlag === true ? active : deactive}
                  onClick={changeOneFlag}
                >Snacks</Button> */}
                <Button style={snacksFlag === true ? active : deactive}
                    onClick={changeSnackFlag}
                >Snacks </Button> 
                <Button style={goanFlag === true ? active : deactive}
                   onClick={changeGoanFlag}
                   >Goan Menu</Button>
                <Button style={indianFlag === true ? active : deactive}
                    onClick={changeIndianFlag}
                 >Indian Menu</Button>
                <Button style={drinksFlag === true ? active : deactive}
                    onClick={changeDrinkFlag}
                  >Drinks & Desserts</Button>  
                   <Button style={riceFlag === true ? active : deactive}
                    onClick={changeRiceFlag}
                    > Rice Menu
                    </Button>   
                    {showOnlineMenu &&  <Button style={onlineItems === true ? active : deactive}
                        onClick={changeOnlineItemsFlag}
                    > Online Menu
                    </Button> }
                    <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"20px"}}
                        >
                            <b>Call us to place order: (416) 792-7060</b> <br />
                            <b>Text us to place order: (647) 863-9249</b>
                        </Typography>           
                     
                          
        <Paper  elevation={12}  style={paperStyle}>  
                {showChoris &&
                            <OrderChoris 
                                    price={price} 
                                    availableName={availableName} 
                                    quantity={quantity}
                            />}
            <Paper  elevation={24}  sx={menuStyle}>       
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    {snacksFlag === true ? 
                    <Grid item xs={12}  >
                        
                        <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"30px"}}
                        >
                            <b>Snacks</b>
                        </Typography>
                        <Typography variant="h6" component="div" gutterBottom
                        style={{fontSize:"20px"}}
                        >
                            Order by the dozen
                        </Typography>
                        
                        <Divider/>
                    <Item>
                                   
                        <Stack spacing={2}>
                   {

                   snacks.map((snack)=>(                  
                         <Snacks key={snack.id} id={snack.id}snack={snack}
                         onAddCart={onAddCart}/>                        
                    ))                  
                } 
                </Stack> 
                        </Item>
                    </Grid>
                  : "" }
                  {goanFlag === true ? 
                    <Grid item xs={12}  >
                        <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"30px"}}
                        >
                            <b> Goan Menu</b>
                        </Typography>
                        <Divider/>
                    <Item>
                                
                        <Stack spacing={2}>
                   {

                Curries.map((curry)=>(                  
                         <Snacks key={curry.id} id={curry.id} name={"Gcurry"} snack={curry}
                         onAddCart={onAddCart}/>                        
                    ))                  
                } 
                    </Stack> 
                        </Item>
                    </Grid>
                    : ""}

              {oneFlag === true ? 
                    <Grid item xs={12}  >
                        <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"30px"}}
                        >
                            <b> Snacks</b>
                        </Typography>
                        <Divider/>
                    <Item>
                                
                        <Stack spacing={2}>
                   {

                  Snack1DB.map((snack1)=>(                  
                         <Snacks key={snack1.id} id={snack1.id}  snack={snack1}
                         onAddCart={onAddCart}/>                        
                    ))                  
                } 
                    </Stack> 
                        </Item>
                    </Grid>
                    : ""}


                {indianFlag === true ?
                    <Grid item xs={12}  >
                    <Item>
                    <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"30px"}}
                        >
                            <b>

                            Indian Cuisine
                            </b>
                        </Typography>    
                        <Divider/>       
                        <Stack spacing={2}>
                   {

            Icurries.map((curry)=>(                  
                         <Snacks key={curry.id} id={curry.id} snack={curry}
                         onAddCart={onAddCart}/>                        
                    ))                  
                } 
                    </Stack> 
                        </Item>
                    </Grid>
                    : "" }
                     {drinksFlag === true ? 
                    <Grid item xs={12} >
                    <Item>
                    <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"30px"}}
                        >
                                <b>
                                Drinks & Desserts
                                </b>
                        </Typography>
                        <Divider/>           
                        <Stack spacing={2}>
                   {
                     
            DrinksDB.map((drink)=>(                  
                         <Snacks key={drink.id} id={drink.id} snack={drink}
                         onAddCart={onAddCart}/>                        
                    ))                  
                } 
                    </Stack> 
                        </Item>
                    </Grid>
                   : ""}

            {riceFlag === true ? 
                    <Grid item xs={12} >
                    <Item>
                    <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"30px"}}
                        >
                                <b>
                                Rice Menu
                                </b>
                        </Typography>
                        <Divider/>           
                        <Stack spacing={2}>
                   {
                     
            RiceDB.map((drink)=>(                  
                         <Snacks key={drink.id} id={drink.id} snack={drink}
                         onAddCart={onAddCart}/>                        
                    ))                  
                } 
                    </Stack> 
                        </Item>
                    </Grid>
                   : ""}
                   {showOnlineMenu === true ? 
                    <Grid item xs={12} >
                    <Item>
                    <Typography variant="h5" component="div" gutterBottom
                        style={{fontSize:"30px"}}
                        >
                                <b>
                                Online Menu
                                </b>
                        </Typography>
                        <Divider/>           
                        <Stack spacing={2}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width:"30%", wordBreak:"break-all"}}>Name</th>
                                    <th>Serving <br />size</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                </tr>

                            </thead>
                            <tbody>

                       
                   {
                     
            onlineMeals.map((el, i)=>(       
                    <tr>
                         <OnlineItems key={i} meal={el}
                            onAddCart={onAddCart}
                            onaddItem={addItem}
                            index={i}
                          />                        
                    </tr>          
                    ))        
                } 
                   </tbody>
                </table>
                    </Stack> 
                        </Item>
                        {cart.length > 0 && <OnlineCart />}
                    </Grid>
                   : ""}
                </Grid>
                
                </Box>
            </Paper >      
        </Paper >
        </> }

        </div>   
   
  );
};

export default Banner;
