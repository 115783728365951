const snacks = [
  
    
    {   id:1,
        name:"PAN ROLLS",
        price:"5",
        mealQuantity: "(2 pcs) ",
        description:"A savoury crepe or thin pancake with a filling of either minced meat, prawns or vegetables"
    },  
    
    
    {
        id:2,
        name:"FISH CUTLET",
        price:"5 ",
        mealQuantity: "(2 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    }
    ,
    {   id:3,
        name:"BEEF CUTLET",
        price:"5",
        mealQuantity: "(2 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    },
    {
        id:4,
        name:"SANNAS ",
        price:"5",
        mealQuantity: "(3 pcs) ",
        description:"Steamed Goan Rice cake made from  rice and  toddy"
    },
    {
        id:5,
        name:"JAGGERY SANNAS",
        price:"5",
        mealQuantity: "(2 pcs) ",
        description:"Steamed Goan Rice cake made from rice, jaggery and  toddy"
    },
    {   id:6,
        name:"Pattice (Puff Pastry)",
        price:"5",
        mealQuantity: "(2 pcs) ",
        description:"Flaky light pastry made from  laminated dough  with stuffed chicken or beef or vegetable"
    },
    
    {  id:7,
        name:"BEEF CROQUETTE",
        price:"5",
        mealQuantity: "(2 pcs)",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    },
  
    { id:8,
        name:'Potato Chops',
        price: '5 ',
        mealQuantity: "(2 pcs)",
        description:"Mashed potato deep fried with  stuffing of your choice beef or chicken  "
      },
      { id:9,
        name:'Railway Cutlet ',
        price: '5',
        mealQuantity: "(2 pcs)",
        description:"A snack munching recipe made  particularly popular with the Indian Railways"
      }
 
   

]



export default snacks;