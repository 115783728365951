import React from 'react'
import Header from '../components/Header';
import AboutUs from '../components/Aboutus';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  let navigate = useNavigate(); 

  return (
    <>
   <Helmet >
 <title>Thank You</title>
 <link rel="notImportant" href="https://nostalgiakitchen.ca/success" />
 <meta name="description"   content="About Nostalgia Kitchen " />
 <link rel="canonical" href="https://nostalgiakitchen.ca/success" />
 <meta property="og:title" content="Thank you"/>
 <meta property="og:type" content="website"/>
 <meta property="og:url" content="https://nostalgiakitchen.ca/success"/>
 
</Helmet>
    <div>
        <Header/>
        <div style={{textAlign:"center", height:"250px"}}>
          <h2 style={{ verticalAlign:"center", top:"50%"}}>Thank you for your order. See you at the bazaar on November 18th</h2> 
          <button onClick={()=> navigate("/")} style={{backgroundColor:"darkred", color:"white", fontSize:"26px"}}>Back to nostalgiakitchen.ca</button>
        </div>
        
        <div style={{display:"flex", justifyContent:"center"}}>
        </div>
    <Footer />
    </div>
   
    </>
  )
}

export default SuccessPage