import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import EventsItems from "../components/Eitems";
import Logo from "../assets/images/1136x640.png";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Eitems from '../components/Eitems';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.transparent,
 boxShadow: "none"
}));

const EventPage = () => {
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState([]);
 
  useEffect(()=>{
    let tempItems = [
      {
        name:"Channa Dose",
        price:10,
        Quantity: "1/2 lb"
      },
      {
        name:"Bebinca",
        price:15,
        Quantity: "1lb"
      },
      {
        name:"Baathika",
        price:15,
        Quantity: "1lb"

      },
      {
        name:"Fruit cake",
        price:15,
        Quantity: "1lb"
      },
      {
        name:"Beef/shrimp Panrolls",
        price:5,
        Quantity: "2 pcs"
      },
      {
        name:"Prawn Risois",
        price:5,
        Quantity: "2 pcs"
      },
      {
        name:"Beef potato chops",
        price:5,
        Quantity: "2 pcs"
      },
      {
        name:"Sorpatel",
        price:15,
        Quantity: "24 oz"
      },
 
  ]
  setItems(tempItems)

  },[])

  const check_if_item_exists_in_cart = (item) =>{
    let exists = false
    if(cart.length > 0){
      exists =  cart.filter((el)=> el.name === item.name).length > 0 ?  true : false
      console.log(exists)
     
    } else {
      exists = false
    }

    return exists

  }


  

  const add_to_cart = (i)=>{
    let selected_item = items[i]
   
    if(check_if_item_exists_in_cart(selected_item)){
     let index = cart.findIndex((el)=>el.name === selected_item.name)
     if(index > -1){
        let temp_cart = cart
        temp_cart[index] =  {...temp_cart[index], "quantity": temp_cart[index].quantity + 1, "total": temp_cart[index].price * (temp_cart[index].quantity + 1)}
        setCart([...temp_cart])
     }

    } else {
      selected_item = {...selected_item, "quantity": 1, "total": selected_item.price}
      setCart([...cart, selected_item])
    }


  }

  const remove_from_cart = (i)=>{
   let temp_cart = cart
    temp_cart = temp_cart.filter(el => el.name !== cart[i].name)
    setCart(temp_cart)
  }
      return  (
    <>
    <Helmet >
      <title>Events</title>
      <link rel="notImportant" href="https://www.nostalgiakitchen.ca/events" />
      <meta name="description"   content="View Our Menu for events" />
      <link rel="canonical" href="https://www.nostalgiakitchen.ca/events" />
      <meta property="og:title" content="Nostalgia Kitchen - Taste of Goa"/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://www.nostalgiakitchen.ca/events"/>
</Helmet> 
  <Box sx={{ width: '100%' }}> 
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
      <Grid item xs={12}>
            <Item><Header /></Item>
          </Grid>
          <Grid item xs={12} >
            <Item>
              {/* <Eitems /> */}
              No events coming up.
            </Item>
          </Grid>
          
          
        
      </Grid>   
    </Box>
      <Footer />
   
  {/* <div style={{ width: '100%'}}> 
   
       <div>
            <Header />
       </div>
          
      <div style={{display:"flex"}}>
        <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
      <div className='doodoo' style={{
            width:"768px",
          display:"flex",
          // height:"768px",
       
          justifyContent:"center",

          }}>
            <div 
              style={{textAlign:"center",
            backgroundImage:`url(${Logo})`,
            // backgroundRepeat:"no-repeat",
            backgroundPosition:"center",
            backgroundSize:"contain",
            width:"100%",
          // backgroundColor:"purple"
          }}>
              <i style={{fontSize:"36px", color:"darkred", fontFamily:"emoji"}}
              ><b>Winter Bazaar</b>
              </i>
              <br />
              <br />
              <div style={{fontSize:"16px"}}>
                {items.map((el,i)=>(
                  <div key={el.name} style={{textAlign:"center", marginTop:"10px"}}>
                    {el.name}
                    ({el.Quantity}) <br />
                    ${el.price}
                    <div>
                        <button onClick={()=>add_to_cart(i)}style={{borderRadius:"50px", backgroundColor:"white", fontSize:"11px"}}>
                          Add To Cart
                        </button>

                    </div>
                  </div>
                ))}
             
              </div>
              <div style={{width:"100%",display:"flex", justifyContent:'center', backgroundColor:"white"}}>
                    <div>
                          <h2 style={{fontSize:"36px", color:"darkred", fontFamily:"emoji"}}>Your Snack Bag</h2>
                        <div >
                      <table style={{width:"100%"}}>
                        <thead>
                          <tr>
                            <th style={{textAlign:"left"}}>Name</th>
                            <th style={{textAlign:"left",}}>Quantity</th>
                            <th style={{textAlign:"left"}}>Total</th>
                            <th style={{textAlign:"left"}}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart.map( (el, i)=>(
                            <tr>
                              <td style={{textAlign:"left"}}>{el.name}</td>
                              <td style={{textAlign:"left"}}> {el.quantity}</td>
                              <td style={{textAlign:"left"}}>${el.total} </td>
                              <td style={{textAlign:"left"}}><button style={{backgroundColor:"white", border:"1px solid #9e9e9e", borderRadius:"5px", color:"#9e9e9e"}} onClick={()=>remove_from_cart(i)}>Remove</button></td>
                            </tr>
                 
                     
                     
                      ))}
                        </tbody>
                      </table>
                      </div>
                      <div style={{textAlign:"left", borderTop:"5px solid black"}}>
                        Sub-Total: ${cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0)} <br />
                        Tax: ${(cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0) *.13).toFixed(2)} <br />
                        Total: ${((cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0) *.13) + cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0)).toFixed(2)}
                      </div>
                      <div>
                        <button
                        style={{backgroundColor:"darkred", color:"white", borderRadius:"50px", border:"0px solid", marginBottom:"10px"}}
                        >Call 647-863-9249</button>
                      </div>
                     
                     
                    {/* {cart.map( (el)=>(
                    <div>
                      {el.name} {el.price}x{el.quantity} ${el.total}


                    </div>))}
                    </div>
                  </div> 
                 
            </div>
           

          </div>
         
      </div>      

      </div>
    <Footer />
  </div> */}
  </>
  )
};

export default EventPage;
