import {Carousel, Image} from 'react-bootstrap'
import React from 'react';
import {useState, useEffect} from 'react'
import OpenNow from '../assets/images/dazzel1.png';
import Menu from '../assets/images/menu.png';
import Biryani from '../assets/images/Biryani.png';
import tastebuds from '../assets/images/tastebuds/tastebuds-576px.png';
import favSnack from '../assets/images/favSnack/favSnack 576px.png';
import Button from '@mui/material/Button';
import BillysBowl from '../assets/images/ricebowls/billybowl 576.png';
import { GiChicken } from "react-icons/gi";
import { GiCorn } from "react-icons/gi";
import CircularProgress from '@mui/material/CircularProgress';
import { Link} from "react-router-dom";

function Hero2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const [weeklymenu, setweeklymenu] = useState("");
  const [loading, setLoading] = useState(false);
  const [nonveg, set_nonveg] = useState([]);
  const [veg, set_veg] = useState([]);
  const [additional_items, set_additional_items] = useState([]);
  const [disable_submit, set_disable_submit] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [weekly_menu_meals, set_weekly_menu_meals] = useState(false);


  // useEffect(()=>{
  //   setLoading(true)
  //   fetch("https://admin.billfern.ca/get_weekly_Menu")
  //   .then((response)=>response.json())
  //   .then((data)=>{
  //     setweeklymenu(data)
  //     setLoading(false)
      

  // })
  //   .catch(e=>console.log(e))
  // },[])

useEffect(()=>{
  setLoading(true)
  fetch("https://dsqqdxoqddpdvzqosdmm.supabase.co/functions/v1/hello-world", {method: "POST"})
  .then((response)=>response.json())
  .then((data)=>{
  setLoading(false)

    let temp = data.menu[0]
    set_weekly_menu_meals(temp)
    let temp_non_veg = [];
    let temp_veg = [];
    let temp_additional_items = [];
    Object.keys(temp).map((el)=>{
      let temp_obj = {quantity: 0}
      if(el.includes("nonveg")){
        temp_obj["name"] = temp[el]
        temp_non_veg.push(temp_obj)
      }
      if(el.startsWith("veg")){
        temp_obj["name"] = temp[el]
        temp_veg.push(temp_obj)
      }
      if(el.includes("additional_item")){
        if(typeof(temp[el])=== "string"){
          let t = {
            name : temp[el],
            price: temp[el + "_price"],
            quantity: 0
          }
          temp_additional_items.push(t)
        }
      }
    })

    
    set_additional_items(temp_additional_items)

    temp_non_veg = temp_non_veg.map((el, i)=>{
      if(i <= 3){
        return {...el, "quantity": 1}
      } return el
    })

    temp_veg = temp_veg.map((el, i)=>{
      if(i <= 1){
        return {...el, "quantity": 1}
      } return el
    })
    set_nonveg([...temp_non_veg]);
    set_veg([...temp_veg])
    set_additional_items([...temp_additional_items])
    if(data && data.data && data.data.length === 0){
      setShowMenu(false)
      
    } 
 

    

})
  
  .catch(e=>{console.log(e); setLoading(false)})
},[])

  return (
    <>
      <Carousel activeIndex={index} indicators={false} controls={false} onSelect={handleSelect}>
        <Carousel.Item  interval={1500}>
          <Image
            className="d-block w-100 fit"
            src={tastebuds}
            alt="Indulge your taste budds with nostalgia"
            style={{
              maxWidth:"100%",
              height:"50%",            
            }}
            responsive="true"
          />
        
        </Carousel.Item>
        {/* <Carousel.Item  interval={1500}>
          <Image
            className="d-block w-100 fit"
            src={favSnack}
            alt="Favourite snack"
            style={{
              maxWidth:"100%",
              height:"50%",            
            }}
            responsive="true"
          />
        
        </Carousel.Item>
        <Carousel.Item  interval={1500}>
          <Image
            className="d-block w-100 fit"
            src={BillysBowl}
            alt="Billy's bowl"
            style={{
              maxWidth:"100%",
              height:"50%",            
            }}
            responsive="true"
          />
        
        </Carousel.Item> */}
        {/* <Carousel.Item interval={1500}>
          <Image
            className="d-block w-100"
            src={Menu}
            alt="Second slide"
            responsive="true"
            style={{
              maxWidth:"100%",
              height:"50%",
              
              
            }}
            
          />
        <Carousel.Caption style={{alignItems:"center", left:"30%",  position:"absolute", zIndex:"1", bottom:"10%"}}>
        
        </Carousel.Caption>
        
        </Carousel.Item> */}
        
      
      </Carousel>
      <div style={{width:"100%",marginTop:"10px",  height:"100%",justifyContent:"space-around", display:"flex", flexWrap:"wrap"}}>
              <div style={{
                textAlign:"center",
                display: "flex",
                alignItems: "center",
                fontFamily:"monospace"
              }}>
                  <h1 style={{alignItems:"center", fontFamily:"monospace"}}>
                      <b>
                          This Week's Menu 
                      </b>
                      <div style={{display:'flex', justifyContent:"center"}}>
                          <Button size="large" variant="contained" style={{backgroundColor:"#8b0000"}}>
                          <Link to="/weeklymenu" style={{textDecoration:"none", fontSize:"15px", color:"black", padding:"2px", color:"white"}}>
                              Order now
                          </Link>
                          </Button>
                      </div>
                      {/* Six Dishes,<br /> Delivered <br /> to your house <b>Weekly</b> <br /> */}
                  </h1>
              </div>
              <div style={{display:"flex",
                padding:"10px",
                marginBottom:"20px", marginRight:"20px",borderRadius:"15px", justifyContent:"center", flexDirection:"column",  boxShadow: "10px 10px #ffbf00"}}>
                <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                    <div style={{textAlign:"left", marginBottom:"10px", fontSize:"25px"}}>
                      <div style={{borderBottom:"1px dashed black"}}><GiChicken size={30}/> Non-Vegetarian</div>
                          {loading ? 
                          
                          <CircularProgress style={{color:"#8B0000"}}/> 
                          
                          : 
                           <ol>
                            {nonveg.map((el, i)=>(
                              <li style={{display:i >=4 ? "none" : ""}}>{el.name}</li>
                            ))}
                        
                           
                          </ol>
                          
                          }
                         
                          <div style={{borderBottom:"1px dashed black"}}><GiCorn size={20}/> Vegetarian</div>
                          {loading ? 
                          
                          <CircularProgress style={{color:"#8B0000"}}/> 
                          
                          :  <ol>
                                  {veg.map((el, i)=>(
                              <li style={{display:i >=2 ? "none" : ""}}>{el.name}</li>
                            ))}
                        
                            </ol>
                          }
                    </div>
              </div>
                  
            </div>
            </div>     
  
   
   

    </>


  );
}

export default Hero2;



