import React from 'react'
import Header from '../components/Header';
import AboutUs from '../components/Aboutus';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import Header2 from "../components/Common/Header";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.transparent,
 boxShadow: "none"
}));

const ChefPage = () => {
  return (
    <>
   <Helmet >
 <title>About Us</title>
 <link rel="notImportant" href="https://nostalgiakitchen.ca/about" />
 <meta name="description"   content="About Nostalgia Kitchen " />
 <link rel="canonical" href="https://nostalgiakitchen.ca/about" />
 <meta property="og:title" content="Nostalgia Kitchen - About us"/>
 <meta property="og:type" content="website"/>
 <meta property="og:url" content="https://nostalgiakitchen.ca/about"/>
 
</Helmet>
    <div>
          <Item sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                    <Header2 />
                    {/* <Header /> */}
                 
                  </Item>
                  <Item sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <Header />
                 
                  </Item>
        {/* {/* <Header/>
       
         <Header2 /> */}
    <AboutUs />
    <Footer />
    </div>
   
    </>
  )
}

export default ChefPage