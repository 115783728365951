import React from 'react'
import HeadLogo from '../assets/images/nosk1.png'
import { Helmet } from 'react-helmet-async';
const PageNotFound = () => {
  return (
    <div style={{margin: "auto",
        width: "50%",
        }}>
             <Helmet >
 <title>404 -Page Not Found</title>

 <meta property="og:title" content="404"/>

 
</Helmet>
        <h1>
           404
           
        </h1>
        <br />
        <h2>
        Sorry, We couldnt find that page, try nostalgiaKitchen.ca
        </h2>
        <img src={HeadLogo} alt="Headlogo" />
    </div>
  )
}

export default PageNotFound