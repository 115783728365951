import React from 'react';
import {Image, Carousel, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import snacks from './DealsDB'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Snacks from './Snacks'
import Curries from './GCurriesDB'
import Icurries from './ICurriesDB';
import DrinksDB from './DrinksDB';
import {useEffect, useState, useContext} from 'react';
import RiceDB from './RiceDB'
import Snack1DB from './Snack1DB'
import Cart from './Cart.js'
import CartContext from '../Context/CartContext';
import { FixedSizeList } from 'react-window';
import ShowCart from './ShowCart';
import HowItWorks from './HowItWorks';
import { useParams } from "react-router-dom";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import CollectionsIcon from '@mui/icons-material/Collections';
import { SiCodechef } from "react-icons/si";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    alignContent:'right',
    color: theme.palette.text.secondary,
    boxShadow: "none",
   
  
    
  }));
const Banner = () => {
    const paperStyle ={
        padding: '30px 20px', 
        margin: '20px auto',
        backgroundColor:"#FFB81C"
      }
      const menuStyle ={
        padding: '30px 20px', 
        width: {xs:"100%", md:"100%"},
        margin: '20px auto',
        backgroundColor:"white"
      }

      const deactive ={
          backgroundColor:"white",
          color:"black",
          marginRight:"10px",
          border: '1px black solid',
          marginBottom:"10px",
          borderRadius:"0px"
      }

      const active = {
        backgroundColor:"#8B0000",
        color:"white",
        marginRight:"10px",
        marginBottom:"10px",
        border: '1px black solid',
        borderRadius:"0px"
      }

      const [snacksFlag, setSnacksFlag] = useState(true)
      const [goanFlag, setGoanFlag] = useState(false)
      const [indianFlag, setIndianFlag] = useState(false)
      const [drinksFlag, setDrinksFlag] = useState(false)
      const [riceFlag, setRiceFlag] = useState(false)
      const [oneFlag, setOneFlag] = useState(false)
      const {cart, setCart} = useContext(CartContext)
      const [showCart, setShowCart] = useState(false)
      
      const { name } = useParams();
      useEffect(()=>{
          if(name){
              if(name === "snacks"){
                setSnacksFlag(false);
            setGoanFlag(false);
            setIndianFlag(false);
            setDrinksFlag(false);
            setRiceFlag(false)
            setOneFlag(true)
            setCart([])
            setShowCart(false)    
              }
            if(name === "goan"){
                setSnacksFlag(false);
                setGoanFlag(true);
                setIndianFlag(false);
                setDrinksFlag(false);
                setRiceFlag(false)
                setOneFlag(false)
                setCart([])
                setShowCart(false)
            }

            if(name === "indian"){
                setSnacksFlag(false);
                setGoanFlag(false);
                setIndianFlag(true);
                setDrinksFlag(false);
                setRiceFlag(false)
                setOneFlag(false)
                setCart([])
                setShowCart(false)
            }
          
          } else {

              setSnacksFlag(false);
              setGoanFlag(false);
              setIndianFlag(false);
              setDrinksFlag(false);
              setRiceFlag(false)
              setOneFlag(true)
              setCart([])
              setShowCart(false)
          }
      } , [])

      const changeSnackFlag =()=>{
        setSnacksFlag(true)
        setGoanFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setRiceFlag(false)
        setOneFlag(false)
      }
      const changeGoanFlag =()=>{
        setGoanFlag(true)
        setSnacksFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setRiceFlag(false)
        setOneFlag(false)
      }
      const changeIndianFlag =()=>{
        setIndianFlag(true)
        setSnacksFlag(false);
        setDrinksFlag(false);
        setGoanFlag(false)
        setRiceFlag(false)
        setOneFlag(false)

      }
      const changeDrinkFlag =()=>{
        setDrinksFlag(true)
        setSnacksFlag(false);
        setIndianFlag(false)
        setGoanFlag(false)
        setRiceFlag(false)
        setOneFlag(false)
      }

      const changeRiceFlag = () =>{
        setRiceFlag(true)
        setSnacksFlag(false);
        setGoanFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setOneFlag(false)

      }

      const changeOneFlag = () =>{
        setSnacksFlag(false);
        setGoanFlag(false);
        setIndianFlag(false);
        setDrinksFlag(false);
        setRiceFlag(false)
        setOneFlag(true)
      }

     const onAddCart = (temp) =>{

          setCart([...cart, temp])
          setShowCart(true)
          console.log(cart)
      }


      
  return (
    
          <>   
                
                     
        <Paper  elevation={12}  style={paperStyle}>  
            <Paper  elevation={24}  sx={menuStyle}>       
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    
                    <Grid item xs={12}  >
                   <Typography variant="h3">
                   Our Dear Chef, Rose
                   </Typography>
                   <br />
                   <Typography >
                   The secret to nostalgia Kitchen’s authentic tasting goan food is our Chef, Rose. Our Chef is from Goa and knows the traditional taste and methods used to make Goan food. Our food is made with fresh ingredients, love and nostalgia.  The food she cooks is tasty but also generates the inner nostalgia within each goan person, whether it is Goan fish curry with rice or sorpotel, it reminds goans of the food from back home. Nostalgia Kitchen, the name was chosen because of the sentiment that her cooking brings out of goans. 
                   </Typography>
      
                  
                   <br />
                   <br />
                   <Typography variant="h3">

                   Our Statement
                     </Typography>
                     <Typography >
                     Nostalgia Kitchen is committed to bringing you authentic goan food throughout the GTA.  We are a goan family-owned restaurant that loves sharing our culture and food with the GTA. To maintain quality and taste we cook with fresh ingredients that are bought daily. In addition to fresh ingredients, we create an authentic flavor by using traditional methods of cooking. 
                      We take pride in our authentic tasting dishes as well as some selected Indian dishes. These Indian dishes have been hand selected by our family members and are their favorite dishes. These dishes were selected on vacations and trips to local restaurants.

                      </Typography >
                      <br />
                      <br />
                    <div style={{textAlign:"center", justifyContent:"center"}}>
                    <Typography variant="h4">
                      MOG ASUM DI <br />
                      DEV BOREM KORUM

                      </Typography>
                    </div>                        
                      </Grid>
                </Grid>
                
                </Box>
            </Paper >      
        </Paper >
        </>    
   
  );
};

export default Banner;
