const snacks = [
 
    {   id:10,
        name:"PAN ROLLS",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"A savoury crepe or thin pancake with a filling of either minced meat, prawns or vegetables"
    },  
    
    
    {
        id:11,
        name:"FISH CUTLET",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    }
    ,
    {   id:12,
        name:"BEEF CUTLET",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    },
    {
        id:13,
        name:"SANNAS ",
        price:"18",
        mealQuantity: "(12 pcs) ",
        description:"Steamed Goan Rice cake made from rice and  toddy"
    },
    {
        id:14,
        name:"JAGGERY SANNAS",
        price:"20",
        mealQuantity: "(12 pcs) ",
        description:"Steamed Goan Rice cake made from rice, jaggery and  toddy"
    },
    {   id:15,
        name:"Pattice (Puff Pastry)",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"Flaky light pastry made from a laminated dough  with stuffed chicken or beef or vegetable"
    },
    
    {  id:16,
        name:"BEEF CROQUETTE",
        price:"25",
        mealQuantity: "(12 pcs) ",
        description:"A thick binder combined with a filling, which is breaded and deep-fried, and served as a side dish"
    },
  
    { id:17,
        name:'Potato Chops',
        price: '25',
        mealQuantity: "(12 pcs) ",
        description:"Mashed potato deep fried with  stuffing of your choice beef or chicken"
      },
      { id:18,
        name:'Railway Cutlet ',
        price: '25',
        mealQuantity: "(12 pcs) ",
        description:"A snack munching recipe made by particularly popular with the Indian Railways"
      }
 
   

]



export default snacks;