import React, { useEffect } from 'react'
import Logo from "../assets/images/1136x640.png";
import { useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';
import "../assets/css/CheckoutForm.css"



// import bigMenu from "../assets/images/WINTER BAZAAR.png";
const stripePromise = loadStripe("pk_live_51NVZZUAEwIIxJmVCY3JLlePIxwf7SZTRA6OXJZVjPE3QuzZLOQdZRqFXd8u1cQcGwqGEH0vRoOgKkElflIdfztE400S0ztuy6X")
// const stripePromise = loadStripe("pk_test_51NVZZUAEwIIxJmVCQgdc0SIhemGVjyOh2vF18KL6NWmiSFiD7xqvzRbCxQ3XhflJvQyAARUOhdizmHk99GIRelm000zkn6jr6R")


const Eitems = () => {
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [fullname, setFullname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [store, setStore] = useState(false);
  useEffect(()=>{
    let tempItems = [
      {
        name:"Channa Dose",
        price:10,
        Quantity: "1/2 lb"
      },
      {
        name:"Bebinca",
        price:15,
        Quantity: "1lb"
      },
      {
        name:"Baathika",
        price:15,
        Quantity: "1lb"

      },
      {
        name:"Fruit cake",
        price:15,
        Quantity: "1lb"
      },
      {
        name:"Beef/shrimp Panrolls",
        price:5,
        Quantity: "2 pcs"
      },
      {
        name:"Prawn Risois",
        price:5,
        Quantity: "2 pcs"
      },
      {
        name:"Beef potato chops",
        price:5,
        Quantity: "2 pcs"
      },
      {
        name:"Sorpatel",
        price:15,
        Quantity: "24 oz"
      },
 
  ]
  setItems(tempItems)

  },[])

  const check_if_item_exists_in_cart = (item) =>{
    let exists = false
    if(cart.length > 0){
      exists =  cart.filter((el)=> el.name === item.name).length > 0 ?  true : false
      console.log(exists)
     
    } else {
      exists = false
    }

    return exists

  }


  const add_to_cart = (i)=>{
    let selected_item = items[i]
   
    if(check_if_item_exists_in_cart(selected_item)){
     let index = cart.findIndex((el)=>el.name === selected_item.name)
     if(index > -1){
        let temp_cart = cart
        temp_cart[index] =  {...temp_cart[index], "quantity": temp_cart[index].quantity + 1, "total": temp_cart[index].price * (temp_cart[index].quantity + 1)}
        setCart([...temp_cart])
     }

    } else {
      selected_item = {...selected_item, "quantity": 1, "total": selected_item.price}
      setCart([...cart, selected_item])
    }


  }

  const remove_from_cart = (i)=>{
   let temp_cart = cart
    temp_cart = temp_cart.filter(el => el.name !== cart[i].name)
    setCart(temp_cart)
  }

  const get_client_secret = () =>{
    fetch("https://admin.billfern.ca/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json",
             "Access-Control-Origin": "*",
             'Access-Control-Allow-Origin': "*"
    },
      body: JSON.stringify({ items: cart }),
    })
      .then((res) => res.json())
      .then((data) => {
        let intended_session = data.clientSecret.split("_secret_")
        fetch("https://admin.billfern.ca/add_new_event_meal", {
          method: "POST",
          headers: {  
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          'Access-Control-Allow-Origin': "*" },
          body: JSON.stringify({status:"notpaid", intent_payment:intended_session[0],store: store ? "In store" : "Can Orient", items:cart, fullname: fullname, phoneNumber:phoneNumber }),
        })
          .then((res) => res.json())
          .then(() => setClientSecret(data.clientSecret).catch(e=>alert(e))
          );
      
        
        // setClientSecret(data.clientSecret)
      
      }).catch(e=>alert(e))
  }
  
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
      <div style={{
            width:"768px",
          display:"flex",
          // height:"768px",
       
          justifyContent:"center",

          }}>
            <div style={{textAlign:"center",
          backgroundImage:`url(${Logo})`,
          // backgroundRepeat:"no-repeat",
          backgroundPosition:"center",
           backgroundSize:"contain",
          width:"100%",
          // backgroundColor:"purple"
          }}>
              <i style={{fontSize:"36px", color:"darkred", fontFamily:"emoji"}}
              ><b>Winter Bazaar</b> <br />
              November 18th 2023
              </i>
              <br />
              <br />
              <div style={{fontSize:"16px"}}>
                {items.map((el,i)=>(
                  <div key={el.name} style={{textAlign:"center", marginTop:"10px"}}>
                    {el.name}
                    ({el.Quantity}) <br />
                    ${el.price}
                    <div>
                        <button onClick={()=>add_to_cart(i)}
                        style={{borderRadius:"50px", backgroundColor:"white", fontSize:"11px", display:clientSecret ? "none": ""}}>
                          Add To Cart
                        </button>

                    </div>
                  </div>
                ))}
             
              </div>
              <div style={{width:"100%",display:"flex", justifyContent:'center', backgroundColor:"white"}}>
                    <div>
                          <h2 style={{fontSize:"36px", color:"darkred", fontFamily:"emoji"}}>Your Snack Bag</h2>
                        <div >
                      <table style={{width:"100%"}}>
                        <thead>
                          <tr>
                            <th style={{textAlign:"left"}}>Name</th>
                            <th style={{textAlign:"left",}}>Quantity</th>
                            <th style={{textAlign:"left"}}>Total</th>
                            <th style={{textAlign:"left"}}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart.map( (el, i)=>(
                            <tr>
                              <td style={{textAlign:"left"}}>{el.name}</td>
                              <td style={{textAlign:"left"}}> {el.quantity}</td>
                              <td style={{textAlign:"left"}}>${el.total} </td>
                              <td style={{textAlign:"left"}}><button style={{backgroundColor:"white", border:"1px solid #9e9e9e", borderRadius:"5px", color:"#9e9e9e", display:clientSecret ? "none": ""}} onClick={()=>remove_from_cart(i)}>Remove</button></td>
                            </tr>
                 
                     
                     
                      ))}
                        </tbody>
                      </table>
                      </div>
                      <div style={{textAlign:"left", borderTop:"5px solid black"}}>
                        Sub-Total: ${cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0)} <br />
                        Tax: ${(cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0) *.13).toFixed(2)} <br />
                        Total: ${((cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0) *.13) + cart.reduce((accumulator, currentValue) => {
                                return accumulator + currentValue.total
                              },0)).toFixed(2)}
                      </div>
                      <div style={{marginTop:"10px"}}>
                        <input style={{fontSize:"16px"}} placeholder="Enter Full Name" onChange={(e)=>setFullname(e.target.value)} value={fullname}/> <br /> <br />
                        <input style={{fontSize:"16px"}} placeholder="Phone number" onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber}/> <br /> <br />
                        <div style={{display:"flex", justifyContent:"center", gap:"5px"}}>
                        <input type="radio" 
                                id="atEvent"
                                name="atevent"
                                value="atevent" 
                                    onChange={(event) => setStore(false)} 
                                    checked={store === false ? true : false} 
                                    />
									
                        <label htmlFor="atevent">At Can Orient</label>
                        
					        <input type="radio" 
                            id="instore"

                            name="instore"
                            value="instore" 
                            onChange={(event) => setStore(true)} 
                                 checked={store === true ? true : false} 
                                 />
									
                            <label htmlFor="instore">In Store</label>
                           
                            
                        </div>

                        
                        {cart && cart.length >  0 && fullname && fullname.replace(" ","").length > 0 && phoneNumber && phoneNumber.replace(" ","").length > 0 &&  <button
                        onClick={()=>get_client_secret()}
                        style={{display: clientSecret ? "none": "" ,backgroundColor:"darkred", color:"white", borderRadius:"50px", border:"0px solid", marginBottom:"10px"}}
                        >Checkout</button>}
                      </div>
                      <div >
                        {clientSecret && (
                          <Elements  options={options} stripe={stripePromise}>
                            <CheckoutForm />
                          </Elements>
                        )}
                      </div>
                    
                     
                     
                    {/* {cart.map( (el)=>(
                    <div>
                      {el.name} {el.price}x{el.quantity} ${el.total}


                    </div>))} */}
                    </div>
                  </div>
                 
            </div>
           

          </div>
         
    </div>
   
  )
}

export default Eitems
