import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useState} from "react"
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
const OrderChoris = (props) => {
  const [numberChoris, setNumberChoris] = useState(0);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const increase = () =>{
    setNumberChoris(numberChoris + 1)
  }
 
  const decrease = () =>{

    setNumberChoris(numberChoris - 1 > 0 ? numberChoris -1 : 0)
  }

  const submitOrder = (e) =>{
    e.preventDefault();
    if(name === ""){
      setNameError("Please Enter your name")
    }
    if(email === ""){
      setEmailError("Please Enter your email")
    }
    if(phone === ""){
      setPhoneError("Please Enter your Phone number")
    }
    if(name && phone && email && numberChoris){
      setLoading(true)
      let data = {
        name: name,
        phone: phone,
        email: email,
        numberChorisPav: numberChoris,
        totalNumberOfChoris: numberChoris*props.quantity,
        total:((numberChoris*props.price *0.13) + (numberChoris*props.price)).toFixed(2),
        totalBeforeTax: numberChoris*props.price


      }

      fetch("https://admin.billfern.ca/sendOrder",{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      
      } ).then(response=>response.json()).then((data)=>
      
      {
        if(data.data === "success"){
          setLoading(false)
        setName("") 
        setEmail ("") 
        setPhone("") 
        setNumberChoris(0)
        setSuccess(true)
      
      }}
      
      ).catch((e)=>{console.log(e);   setLoading(false)})
    }
  }
  return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
    <div style={{border: " 1px solid #FFB81C", padding:"10px", margin:"10px", borderRadius:"10px", backgroundColor:"white"}}>
    
    <h2>Order Online</h2>
    <div style={{display:"flex", alignItems:"center", justifyContent:"center", paddingLeft:"5px"}}>
    <table>
      <thead>
      <tr>
        <th style={{width:"15%"}}>Name</th>
        <th style={{width:"15%"}}>Price</th>
        <th style={{width:"10%"}}>Quantity</th>
        <th style={{width:"38%"}}>Number #</th>
    
      </tr>

      </thead>
      <tr>
        <td>{props.availableName}</td>
        <td>${props.price}</td>
        <td>{props.quantity}</td>
        <td>
          <Button 
              size="large" 
              onClick={(e)=>increase()}
                style={{
                    border:"1px solid #8B0000" ,
                    color:"white",
                    maxWidth: '30px', 
                    maxHeight: '30px', 
                    minWidth: '30px', 
                    minHeight: '30px', 
                    color:"#8B0000",
                      margin:"10px",
                }}
                >+</Button> {numberChoris}
        
        <Button 
         onClick={(e)=>decrease()}
        style={{
              border:"1px solid #8B0000" ,
              
              color:"white",
              maxWidth: '30px', 
              maxHeight: '30px', 
              minWidth: '30px', 
              minHeight: '30px', 
              color:"#8B0000",
               margin:"10px"
        }}>     -</Button></td>
        
      </tr>
    </table>
  
    </div>
    <hr
        style={{
            color: "black",
            backgroundColor:  "black",
            height: 5
        }}
    />
     <b>Sub Total:</b> ${numberChoris*props.price}
    <br/><b>Tax:</b> ${(numberChoris*props.price *0.13).toFixed(2)} 
     <br/> <b>Total: ${((numberChoris*props.price *0.13) + (numberChoris*props.price)).toFixed(2)} </b>
   
      <br />
      <br />
      <h2>Contact Info</h2> 
      <Stack spacing={2}>
        <TextField size="small" id="standard-basic" label="Name" variant="outlined" error={nameError}  value={name} onChange={(e)=>{setName(e.target.value); setNameError("")}}/>     
        <span style={{color:"red"}}>{nameError}</span>
        <TextField size="small" id="standard-basic" label="Email" variant="outlined" error={emailError} value={email} onChange={(e)=>{setEmail(e.target.value); setEmailError("")}}/>   
        <span style={{color:"red"}}>{emailError}</span>
        <TextField size="small" id="standard-basic" label="Phone Number" error={phoneError} value={phone} variant="outlined" onChange={(e)=>{setPhone(e.target.value); setPhoneError("")}}/>    
        <span style={{color:"red"}}>{phoneError}</span>
      </Stack>
      <br />
      {loading ? <CircularProgress style={{color:"#8B0000"}}/> : <Button onClick={(e)=>submitOrder(e)}style={{  backgroundColor:"#8B0000",
        color:"white",
        marginRight:"10px",
        marginBottom:"10px",
        border: '1px black solid',
        borderRadius:"0px"}}>Place Order</Button> }
        <br />
       {success && <span style={{color:"darkgreen"}}> Order Placed Successfully. <br /> Thank You For Choosing Us</span>}
      </div> 
   </div>
  )
}

export default OrderChoris