import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Cart from "../../Context/CartContext"
import { useContext, useEffect, useState } from 'react';
const OnlineItems = (props) => {
    const {cart, setCart} = useContext(Cart);
    const [quantity, setQuantity] = useState(0);

    useEffect(()=>{
      let index = cart.findIndex((element) => element._id === props.meal._id)
       if(index < 0){
        setQuantity(0)
       } else {
        setQuantity(cart[index].description)
       }
     
    }, [cart])
    
    const addItem = ()=>{
      let index = cart.findIndex((element) => element._id === props.meal._id)
      setQuantity(quantity => quantity + 1) 
      if(index < 0){
        let meal = props.meal
        meal.description = quantity + 1
        setCart([...cart, meal])

      } else {
      
        const temp = cart.map((el)=>{
          if(el._id === props.meal._id){
         
            return  {
              ...el,
              description: el.description + 1
            }

          } else {
            return el
           }
        })
        setCart(temp)
        // console.log(temp[index])
        // temp[index].description = temp[index].description + 1 
    
      }
      // let meal = props.meal
      // meal.description = quantity + 1
      // let temp = cart
      //  setQuantity(quantity => quantity + 1)    
      //   let index = temp.findIndex((element) => element._id === props.meal._id)        
      //   setCart(current =>
      //     current.map(obj => {
      //       if (props.meal._id === obj._id) {
      //         return {...obj,  description: obj.description };
      //       }
    
      //       return obj;
      //     }),
      //   );
       
      //   if(index >= 0){       
      //     temp[index] = meal
                   
      //     // setCart([1,3,4,5,6])
      //   } else{
      //     // temp.push(meal)
      //     setCart([...cart, meal])
      //   }
        
       
    }
    
    const minusItem = ()=>{
      if(quantity -1 > -1){
        setCart(current =>
          current.map(obj => {
            if (props.meal._id === obj._id) {
              return {...obj,  description: obj.description - 1};
            }
    
            return obj;
          }),
        );
        setQuantity(quantity => quantity - 1)
      }
      if(quantity -1 == 0){
        setCart(current => current.filter(obj =>{
          return props.meal._id !== obj._id
        }))
      }
    }
  return (
    <>    
   
        <td style={{width:"30%", wordBreak:"break-all"}}>{props.meal.name}</td>
        <td>{props.meal.quantity}</td>
        <td>{props.meal.price}</td>
        <td><AddCircleOutlineIcon 
        onClick={(e)=>{addItem()}}
        style={{padding:"2px"}}
        /> {quantity} <RemoveCircleOutlineIcon style={{padding:"2px"}} onClick={(e)=>{minusItem()}}/></td>

    </>
  )
}

export default OnlineItems