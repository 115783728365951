const Drinks = [

    {
        id:42,
        name:"Plain Basmati Rice",
        price:"5",
        mealQuantity:"24oz"
      
    },

   
     {
        id:43,
        name:"Bukhari rice",
        price:"5",
        mealQuantity:"24oz"
    }
    ,
 
  
    {   id:44,
        name:"Jeera Rice",
        price:"5",
        mealQuantity:"24oz"
       
    },
    {   id:45,
        name:"Ghee Rice",
        price:"5",
        mealQuantity:"24oz"
     
    }, 
  
   
    
    
   
    
    

]




export default Drinks;