import { BrowserRouter as Router, Routes, Route,Navigate,useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import CartContext from "./Context/CartContext"
import { useState } from "react";
import MenuPage from './Pages/MenuPage';
import Scroll from './components/Scroll';
import ChefPage from './Pages/ChefPage'
import PageNotFound from './components/PageNotFound';
import WeeklyMenuPage from "./Pages/WeeklyMenuPage";
import EventsPage from "./Pages/EventsPage";
import SuccessPage from "./Pages/SuccessPage";

function App() {
  const [cart, setCart] = useState([]);

  return (
    <CartContext.Provider value={{cart, setCart}}>
    <Router>
        <Scroll />
    <Routes> 
    
      <Route exact path="/" element={<HomePage />} /> 
      <Route exact path="/menu" element={<MenuPage />} /> 
      <Route exact path="/menu/:name" element={<MenuPage />} /> 
      <Route exact path="/about" element={<ChefPage />} /> 
      <Route exact path="/weeklymenu" element={<WeeklyMenuPage />} /> 
      <Route exact path="/events" element={<EventsPage />} /> 
      <Route exact path="/success" element={<SuccessPage />} /> 

      <Route path='*' exact={true} element={<PageNotFound />} /> 

     
    </Routes>
    </Router>
          </CartContext.Provider>
  );
}

export default App;
