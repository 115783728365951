import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartContext from '../Context/CartContext';
import {useState, useEffect, useContext} from 'react'
import Cart from './Cart'
import Grid from '@mui/material/Grid';
import HeadLogo from '../assets/images/nosk1.png'
import {Image} from 'react-bootstrap'
import Badge from '@mui/material/Badge';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  
  const { children, onClose, ...other } = props;
  
  return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
          <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);
    const {cart, setCart} = useContext(CartContext)
    
    const handleClickOpen = () => {
        setOpen(true);
      
    };
    const handleClose = () => {
    setOpen(false);
    let tempCart = cart.filter(el=>el.quantity !== 0)
    setCart([...tempCart])    
    var requestOptions = {
      method: 'GET',
      headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
      
      },
      
    };

   
    cart.forEach(el=>{
      fetch(`https://nostalgiakitchen.ca/api/?username=${el.id}`, requestOptions)
      .then((response) => response.json())
      .then((json)=>console.log(json))
      .catch((err)=>{
        console.log(err)
      })
      console.log(el.id)
    })
   
    
    
  };

  //plus button on cart
  const onAddItem = (id) =>{
    let foundItem = cart.findIndex(item=>item.id === id)
    let tempArray = cart
    
      tempArray[foundItem] = {
        id:  cart[foundItem].id,  
        name : cart[foundItem].name,
        quantity: cart[foundItem].quantity+1,
        price: parseInt(cart[foundItem].price),
        mealQuantity:cart[foundItem].mealQuantity

      }
   
       
      setCart([...tempArray])    
    
  }

  const onRemoveItem = (id) =>{
    let foundItem = cart.findIndex(item=>item.id === id)
    let tempArray = cart
     if(cart[foundItem].quantity-1 !== -1){

       tempArray[foundItem] = {
         id:  cart[foundItem].id,  
         name : cart[foundItem].name,
         quantity: cart[foundItem].quantity-1,
         price: parseInt(cart[foundItem].price),
         mealQuantity:cart[foundItem].mealQuantity
 
       }
     }
   
       
      setCart([...tempArray])    
    
  }

  const ondeleteItem = (id) =>{
    let tempArray = cart.filter(item=>item.id !== id)
      
      setCart([...tempArray])    
    
  }

  return (
    <div>
    <Button size="large"style={{backgroundColor:"#FFB81C", borderRadius:"10px"}} onClick={handleClickOpen}
    startIcon={ 
      <Badge badgeContent={cart.length}    color="error">
        
        <ShoppingCartIcon fontSize="large" style={{color:"white"}}/> 
      </Badge>
    }>
           <Typography style={{color:"white"}}>
            {cart.length}         
           </Typography>
          </Button>
          
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Image src={HeadLogo} style={{height:"10vh"}} alt="Logo"/>   Your Order Summary 
        </BootstrapDialogTitle>
        <DialogContent >
              
        <table style={{padding:"10px"}}>
            <tbody>      
          {   
              cart.map((item)=>(

                  <Cart key={item.id} 
                  id={item.id} name={item.name} price={item.price*item.quantity} 
                  quantity={item.quantity}
                  mealQuantity={item.mealQuantity}
                  onAddItem={onAddItem}
                  onRemoveItem={onRemoveItem}
                  ondeleteItem={ondeleteItem}
                  />           
              ))
          }       
            </tbody>
            </table>
            
          
          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
